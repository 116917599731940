import { rolesThatCanBeInvitedToSystem } from '~/authorization/index'

export default () => {
  const runtimeConfig = useRuntimeConfig()
  const { signOut } = useAuth()
  const { isRole } = useAuthorization()
  const notification = useNotification()

  const sessionLengthInMs = (runtimeConfig.public.externalRoleSessionLength as number) * 60 * 1000

  const sessionTimeoutTime = useLocalStorage('timeoutSignInTime', (): number | null => null)
  const sessionTimeoutInterval = useState('timeoutSignOutInterval', (): NodeJS.Timeout | undefined => undefined)

  onMounted(() => {
    checkLogoutTimeout()
  })

  const clearTimeout = () => {
    clearInterval(sessionTimeoutInterval.value)
    sessionTimeoutInterval.value = undefined
    sessionTimeoutTime.value = null
  }

  const checkTime = () => {
    if (!sessionTimeoutTime.value) {
      return
    }

    const currentTime = Date.now()
    if (currentTime > sessionTimeoutTime.value) {
      clearTimeout()
      notification.info({ title: 'Ihre Sitzung ist abgelaufen. Sie werden ausgeloggt', duration: 4500 })
      setTimeout(signOut, 4500)
    }
  }

  const checkLogoutTimeout = () => {
    if (!isRole(rolesThatCanBeInvitedToSystem, false) || sessionTimeoutInterval.value) {
      return
    }

    sessionTimeoutTime.value ??= Date.now() + sessionLengthInMs

    checkTime()
    sessionTimeoutInterval.value = setInterval(checkTime, 1000 * 60) // once a minute
  }

  return { checkLogoutTimeout, clearTimeout }
}
